import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToInstance, instanceToPlain, plainToInstance } from 'class-transformer';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { Company } from '../../../../models/company.model';
import { PusherService } from '../../../../services/pusher.service';
import { simpleHash } from '../../../../utilities/json-tools';
import { Contract } from '../../contracts/models/contract.model';
import { Negotiation } from '../models/negotiation.model';

@Injectable({
  providedIn: 'root'
})
export class NegotiationService {

  private companyUrl = '/:apiBase/companies/:companyId';
  private companyNegotiationsUrl = this.companyUrl + '/negotiations';
  private negotiationsUrl = this.companyNegotiationsUrl + '/:negotiationId';
  private negotiationRepresentedUrl = this.negotiationsUrl + '/represented';
  private negotiationBrokerUrl = this.negotiationsUrl + '/broker';
  private suggestedNegotiationsUrl = this.negotiationsUrl + '/suggestion-to-link';
  private linkNegotiationsUrl = this.negotiationsUrl + '/link';
  private bookingDateUrl = this.negotiationsUrl + '/original-booking-date';
  private accountsNegotiationAssigneesUrl = this.negotiationsUrl + '/assignee';
  // private ownerBehindBrokerUrl = this.negotiationsUrl + '/represented/:brokerId';
  // TODO: The path of this resource do not follow the conventions
  private negotiationRelatedContractsPath = this.negotiationsUrl + '/contracts-to-link';

  private _nCollectionSubjects: { [eventKey: string]: BehaviorSubject<Negotiation> } = {};
  private _lCollectionSubjects: { [eventKey: string]: BehaviorSubject<Negotiation[]> } = {};

  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
  ) { }

  public watch(companyId: number, negotiationId: number): Observable<Negotiation> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._nCollectionSubjects,
        key: eventKey,
        getData: () => this.get(companyId, negotiationId)
      },
      {
        channel: `company_${companyId}`,
        event: 'negotiation'
      }
    );
  }

  public get(companyId: number, negotiationId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.get<Negotiation>(url).pipe(
      map(negotiation => {
        return plainToInstance(Negotiation, negotiation);
      })
    );
  }

  public represented(companyId: number, negotiationId: number, companies: Company[]): Observable<any> {
    const url = this.negotiationRepresentedUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.post(url, companies);
  }

  public broker(companyId: number, negotiationId: number, broker: Company): Observable<any> {
    const url = this.negotiationBrokerUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.post(url, {
      id: broker.id
    });
  }

  public getSuggested(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    const url = this.suggestedNegotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.get<Negotiation[]>(url).pipe(map(negotiation => {
      return plainToInstance(Negotiation, negotiation);
    }));
  }

  public watchLinked(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._lCollectionSubjects,
        key: eventKey,
        getData: () => this.getLinked(companyId, negotiationId)
      },
      {
        channel: `company_${companyId}`,
        event: 'linked_negotiation'
      }
    );
  }

  private getLinked(companyId: number, negotiationId: number): Observable<Negotiation[]> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.get<Negotiation[]>(url).pipe(
      map(negotiation => {
        return plainToInstance(Negotiation, negotiation);
      })
    );
  }

  public unlink(companyId: number, negotiationId: number, negotiations: number[]): Observable<any> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    const body = { negotiations_to_unlink: negotiations };

    return this.http.put(url, body);
  }

  public link(companyId: number, negotiationId: number, negotiations: number[]): Observable<any> {
    const url = this.linkNegotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    const body = { negotiations_to_link: negotiations };

    return this.http.post(url, body);
  }

  /**
   * Edits the Negotiation
   * [[Negotiation.original_booking_date|original booking date]].
   */
  public editBookingDate(companyId: number, negotiationId: number, date: Date): Observable<Negotiation> {
    const url = this.bookingDateUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    const body = { original_booking_date: date };

    return this.http.put<Negotiation>(url, body).pipe(map(response => {
      return plainToInstance(Negotiation, response);
    }));
  }

  /** @ignore */
  private saveAssignee(negotiationId: number, companyId: number, accountsAssigness: any): Observable<any> {
    const url = this.accountsNegotiationAssigneesUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiationId.toString());

    return this.http.post<any>(url, accountsAssigness);
  }

  /**
   * Get Contracts that meet all these conditions:
   * - Same Product
   * - Same buyer Company (only if buyer is !this.buyer.activity.broker)
   * - Same seller Company (only if buyer is !this.seller.activity.broker)
   * - Is not linked to any Negotiation
   */
  public relatedContracts(companyId: number, negotiation: Negotiation): Observable<Contract[]> {
    const url = this.negotiationRelatedContractsPath
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    return this.http.get<Contract[]>(url).pipe(map(response => {
      return plainToInstance(Contract, response);
    }));
  }

  public acceptRequest(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    negotiation.status = { id: 13, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public acceptCancelation(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    negotiation.status = { id: 22, name: null };

    const data = instanceToPlain(negotiation);

    return this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));
  }

  public udpateStatus(negotiation: Negotiation, companyId: number, statusId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    const tempNegotiation: Negotiation = instanceToInstance(negotiation);

    tempNegotiation.status = { id: statusId, name: null };

    return this.http.put<Negotiation>(url, tempNegotiation).pipe(map(response => {
      return tempNegotiation;
    }));
  }

  public reject(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    if (companyId !== negotiation.company.id && negotiation.status.id === 3) {
      negotiation.status = { id: 8, name: null };
    } else if (
      (companyId === negotiation.company.id && negotiation.status.id === 1) ||
      (companyId !== negotiation.company.id && negotiation.status.id === 2) ||
      (companyId === negotiation.company.id && negotiation.status.id === 3)
    ) {
      // Cancelled
      negotiation.status = { id: 5, name: null };
    } else {
      // Rejected
      negotiation.status = { id: 6, name: null };
    }

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public rejectRequest(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    // Request negotiation rejected
    negotiation.status = { id: 14, name: null };

    const data = instanceToPlain(negotiation);

    const stream = this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));

    return stream;
  }

  public accept(negotiation: Negotiation, companyId: number): Observable<Negotiation> {
    const url = this.negotiationsUrl
      .replace(':companyId', companyId.toString())
      .replace(':negotiationId', negotiation.id.toString());

    negotiation.status = { id: 7, name: null };

    const data = instanceToPlain(negotiation);

    // this.intercomService.track('order-booked', {
    //   order_id: negotiation.order.id,
    //   product_name: negotiation.order.product.name
    // });

    return this.http.put<Negotiation>(url, data).pipe(map(neg => {
      return negotiation;
    }));
  }
}

<div class="color-badges-container">
  <button *ngFor="let color of palette"
  type="button"
  class="ag-color-badge" [style.backgroundColor]="color"
  [class.selected]="isSelected(color)" (click)="onSelect(color)">
</button>
</div>

<div class="color-selector-container">
  <input type="color"
  id="color_picker"
  (change)="onSelect($event.target.value)"
  [value]="statusColor">

  <span class="text-example" [style.color]="statusColor">
    {{ exampleText }}
  </span>
</div>
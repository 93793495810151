import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapsAPILoader } from '@ng-maps/core';
import { v4 as uuidv4 } from 'uuid';

import { Order } from '../../../company/modules/commercial/models/order.model';
import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'gmap-view',
  templateUrl: './gmap-view.component.html',
  styleUrls: ['./gmap-view.component.scss']
})
export class GmapViewComponent implements AfterViewInit {

  @Input() set ordersByProduct(data: GroupBy<Product, Order>[]) {
    this.uniqueIds = {}; // Reset IDs map

    let uniqueId = new Date().getTime();

    data.forEach((group, gindex) => {
      group.values.forEach((order, oindex) => {
        this.uniqueIds[gindex + '_' + oindex] = uniqueId++; // This allows the UI to identify Order with no ID
      });
    });

    this._ordersByProduct = data;
  }
  get ordersByProduct(): GroupBy<Product, Order>[] {
    return this._ordersByProduct;
  }

  @Input() public companyId: number;
  @Input() public company: Company;

  public map;
  public uniqueIds: any;

  private UUID: string;
  private _ordersByProduct: GroupBy<Product, Order>[];
  private currentInfowindow;
  private latlngbounds: google.maps.LatLngBounds;
  private locations_id: Map<string, number>;
  private markers: google.maps.marker.AdvancedMarkerElement[] = [];

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private router: Router
  ) {
    this.UUID = 'map-' + uuidv4();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  /**
   * Initializes the Google Map and configures markers, clustering, and bounds adjustment.
   * 
   * This method sets up the Google Map instance using provided settings. It iterates over
   * orders grouped by product to add markers to the map, enables marker clustering, and
   * adjusts the map's center and zoom to include all markers.
   */
  private initMap(): void {
    // Initialize a map to track location offsets for geolocations.
    this.locations_id = new Map();

    // Load the Google Maps API and initialize the map instance.
    this.mapsAPILoader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        maxZoom: 16,
        minZoom: 3,
        mapId: this.UUID
      });

      // Define LatLngBounds to adjust the map view to include all markers.
      this.latlngbounds = new google.maps.LatLngBounds();

      // Iterate over the orders grouped by product to add markers to the map.
      this.ordersByProduct.forEach((product, i) => {
        product.values.forEach((order, j) => {
          this.addMarker(order, this.uniqueIds[i + '_' + j]);
        });
      });

      // Initialize and apply marker clustering to the map.
      const markerCluster = new MarkerClusterer({
        map: this.map,
        markers: this.markers
      });

      // Adjust the map view to fit all markers within bounds.
      this.map.setCenter(this.latlngbounds.getCenter());
      this.map.fitBounds(this.latlngbounds);
    });
  }

  /**
   * Adds a marker to the map for a specific order and associates it with a unique ID.
   * 
   * @param {Order} order - The order object containing geolocation details.
   * @param {number} uniqueId - A unique identifier for the marker.
   * 
   * This method:
   * - Adds markers at specified geolocations for an order.
   * - Avoids overlapping markers by applying a slight offset.
   * - Sets up an InfoWindow for displaying additional information on marker click.
   * - Extends the map bounds to include the marker's location.
   * - Adds the marker to a clustering collection.
   */
  private addMarker(order: Order, uniqueId: number): void {
    // Check if geolocation data is available; if not, exit early.
    if (!order.business_detail.delivery.geolocations) return;

    // Iterate through each geolocation to add a marker.
    order.business_detail.delivery.geolocations.forEach((geolocation) => {
      // Avoid overlapping by applying a small offset for repeated locations.
      if (this.locations_id[geolocation.place_id] != undefined) {
        this.locations_id[geolocation.place_id] += 0.002;
      } else this.locations_id[geolocation.place_id] = 0;

      const lng = geolocation.geometry.location.lng + this.locations_id[geolocation.place_id],
        lat = geolocation.geometry.location.lat + (Math.random() * 0.002) - 0.001,
        scope = this;

      // Find the HTML content associated with the marker.
      let elem = document.getElementById(uniqueId + '_' + geolocation.place_id).firstElementChild;
      if (!elem) return;

      // Create an InfoWindow to display on marker click.
      const infowindow = new google.maps.InfoWindow({
        // maxWidth: 250,
        content: elem
      });

      // Create a new marker on the map at the specified location.
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: lat, lng: lng },
        map: this.map
      });

      // Set up a click event listener to show the InfoWindow.
      marker.addListener('click', function (): void {
        if (scope.currentInfowindow) {
          scope.currentInfowindow.close();
        }
        scope.currentInfowindow = infowindow;
        infowindow.open(scope.map, marker);
      });

      // Extend the bounds to include this marker's position.
      this.latlngbounds.extend(marker.position);

      // Add the marker to the clusterable marker collection.
      this.markers.push(marker);
    });
  }

  public gotoOrder(id: string): void {
    if (id) this.router.navigateByUrl('/company/' + this.companyId + '/order/' + id);
    else if (!this.company) this.router.navigateByUrl('/login');
  }
}

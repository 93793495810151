import { AfterContentChecked, Directive, EventEmitter, Input, OnDestroy, Optional, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TrackedGroupDirective } from './tracked-group.directive';

@Directive({
  selector: '[trackedControl]'
})
export class TrackedControlDirective implements OnDestroy, AfterContentChecked {

  @Input() previousValue: any;
  @Input() compareWith: (a: any, b: any) => boolean;
  @Input() displayAs: (a: any) => string;

  @Output('discard') public readonly discardEmitter = new EventEmitter();

  initialValue: any;

  public hasChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasDirtyChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private setupDone = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private model: NgModel,
    @Optional() private trackedGroup: TrackedGroupDirective
  ) {
    if (trackedGroup) {
      trackedGroup.addControl(this);
    }
  }

  ngAfterContentChecked(): void {
    if (this.model && !this.setupDone) {
      this.setupModel();
      this.setupDone = true;
    }
  }

  private setupModel(): void {
    this.checkValue(this.model.value);
    this.subscriptions.push(this.model.valueChanges.subscribe(value => {
      this.checkValue(value);
    }));
  }

  checkValue(value: any) {
    if (this.model.pristine) {
      // Update initial value
      this.initialValue = value;
      this.hasChanges.next(!this.equals(value, this.previousValue));
    } else {
      let equalsInitialValue = this.equals(value, this.initialValue);
      this.hasDirtyChanges.next(!equalsInitialValue);
      if (equalsInitialValue) {
        this.discard();
      }
    }
  }

  private equals(a, b): boolean {
    return this.compareWith ? this.compareWith(a, b) : a == b;
  }

  displayInitial(): string {
    return this.display(this.initialValue);
  }

  displayPrevious(): string {
    return this.display(this.previousValue);
  }

  private display(a): string {
    return a && this.displayAs ? this.displayAs(a) : a;
  }

  discard(): void {
    this.model.reset(this.initialValue);
    this.discardEmitter.emit();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ag-text-in-place',
  templateUrl: './text-in-place.component.html',
  styleUrls: ['./text-in-place.component.scss']
})
export class TextInPlaceComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public value: string;
  @Input() public label: string;
  @Input() public disabled: boolean;

  @Output() readonly submitted = new EventEmitter<string>();

  public tempValue: string;
  public uuid: string;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService
  ) {
    this.uuid = uuidv4();
  }

  public open(): void {
    this.tempValue = this.value.toString();
    // this.focus();
    this.openModal(this.modal);
  }

  public submit(): void {
    if (this.tempValue !== this.value) {
      this.submitted.emit(this.tempValue);
      this.value = this.tempValue.toString();
    }
    this.closeModal();
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}

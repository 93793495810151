import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import Pusher, * as PusherTypes from 'pusher-js';
import { BehaviorSubject, Observable, Subscription, debounceTime } from 'rxjs';

import { environment } from '../../environments/environment';
import { PusherMessage } from '../models/pusher-message.model';

interface ChannelEvent {
  channel: string;
  event: string;
}

@Injectable()
export class PusherService {

  private channels: { [key: string]: PusherTypes.Channel } = {};
  private documentHidden: boolean = false;
  private loaderSubscriptions: Subscription[] = [];
  private loaderInProgress: { [pusherIndex: number]: boolean } = {};
  private pendingLoaders: { [key: string | number]: Function } = {};
  private pusher: Pusher;
  // Store subscriptions in memory.
  private pusherSubscriptions: Subscription[] = [];
  private pusherIndex: number = 0;

  constructor(
    @Inject(DOCUMENT) public document: Document
  ) {

    if ('onvisibilitychange' in this.document) {
      this.document.addEventListener("visibilitychange", () => {
        this.documentHidden = document.hidden;
        this.checkPendings();
      });
    }

    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster
      // encrypted: true
    });
  }

  /**
   * If the document is visible again execute any pending loaders
   */
  private checkPendings(): void {
    if (!document.hidden) {
      for (const key in this.pendingLoaders) {
        this.pendingLoaders[key]();
      }
      this.pendingLoaders = {};
    }
  }

  /**
    * Listens to a specified event on a Pusher channel and returns an Observable for the event data.
    * 
    * @param {string} channelId - The ID of the Pusher channel to subscribe to.
    * @param {string} eventId - The ID of the event to listen for.
    * @param {number} [dueTime=500] - The debounce time in milliseconds before emitting the event data.
    * @returns {Observable<PusherMessage>} - An Observable emitting the event data.
    */
  public listen(channelId: string, eventId: string, dueTime: number = 500): Observable<PusherMessage> {
    return new Observable<PusherMessage>(observer => {
      if (!this.channels[channelId]) {
        this.channels[channelId] = this.pusher.subscribe(channelId);
      }

      const channel = this.channels[channelId];
      const callback = (data: PusherMessage) => { observer.next(data); };

      channel.bind(eventId, callback);
      //observer.complete();

      return () => {
        // TODO: Posible leak, asegurarse que se llame siempre.
        channel.unbind(eventId, callback);
      }
    }).pipe(
      debounceTime(dueTime)
    );
  }

  /**
   * Subscribes to multiple events across different Pusher channels and emits a unified stream of event data.
   * 
   * @param {Array<{channel: string, event: string}>} subscriptions - An array of objects specifying the channels 
   * and events to subscribe to. Each object must have the following properties:
   *   - `channel` (string): The name of the Pusher channel to subscribe to.
   *   - `event` (string): The name of the event to listen for on the specified channel.
   * @param {number} [dueTime=500] - The debounce time in milliseconds to throttle event emissions.
   * @returns {Observable<PusherMessage>} - An Observable emitting the event data.
   * 
   * @example
   * const subscriptions = [
   *   { channel: 'public', event: 'order' },
   *   { channel: 'company_123', event: 'negotiation' }
   * ];
   * 
   * this.pusherService.listenToMultiple(subscriptions).subscribe({
   *   next: (data) => {
   *     console.log(`Received event:`, data);
   *   },
   *   error: (err) => console.error('Error:', err),
   *   complete: () => console.log('All subscriptions closed')
   * });
   */
  public listenToMultiple(
    subscriptions: ChannelEvent[],
    dueTime: number = 500
  ): Observable<PusherMessage> {
    return new Observable<PusherMessage>(observer => {
      // Mantenemos un mapa para rastrear los canales suscritos
      const activeSubscriptions: { [channel: string]: { event: string; callback: Function }[] } = {};

      // Suscribirse a todos los eventos en los canales especificados
      subscriptions.forEach(({ channel, event }) => {
        if (!this.channels[channel]) {
          this.channels[channel] = this.pusher.subscribe(channel);
        }

        const callback = (data: PusherMessage) => { observer.next(data); };

        this.channels[channel].bind(event, callback);

        // Guardamos la información de la suscripción activa para desuscribirnos después
        if (!activeSubscriptions[channel]) {
          activeSubscriptions[channel] = [];
        }
        activeSubscriptions[channel].push({ event, callback });
      });

      // Función para limpiar todas las suscripciones al cancelar el Observable
      return () => {
        for (const channel in activeSubscriptions) {
          const channelSubscriptions = activeSubscriptions[channel];
          channelSubscriptions.forEach(({ event, callback }: { event: string; callback: Function }) => {
            this.channels[channel].unbind(event, callback); // Desuscribir eventos
          });
        }
      };
    }).pipe(
      debounceTime(dueTime) // Aplica el tiempo de debounce a todos los eventos
    );
  }

  /**
    * Creates a function to load data and update the corresponding subject.
    *
    * @param {any} collection - The subjects collection.
    * @param {string} key - The key of the subject in the collection.
    * @param {Function} getData - The function to get the data.
    * @returns {Function} - The created load function.
    */
  private loader<T>(collection: { [key: string]: BehaviorSubject<T> }, key: string | number, getData: () => Observable<T>, pusherIndex: number): (check?: boolean) => void {
    return (check: boolean = false): void => {
      const subject = collection[key];
      if (!subject) return;

      if (check && !subject.observed) {
        // If it's not observed stop requests.
        this.pusherSubscriptions[pusherIndex].unsubscribe();
        delete collection[key];
      } else {
        if (!this.loaderInProgress[pusherIndex]) {
          // Make sure it's not waiting for response.
          this.loaderInProgress[pusherIndex] = true;

          this.loaderSubscriptions.push(getData().subscribe({
            next: response => {
              delete this.loaderInProgress[pusherIndex];
              setTimeout(() => {
                this.checkPendings();
              }, 500); // Add a minimum delay to prevent bursts
              subject.next(response);
            },
            error: error => {
              delete this.loaderInProgress[pusherIndex];
              // If an error was received, no further calls should be done.
              delete this.pendingLoaders[key];
              subject.error(error);
            }
          }));
        }
      }
    }
  }

  /**
   * Manages a collection of BehaviorSubjects, ensuring they are loaded and updated.
   * Handles multiple triggers in short periods of time and prevent updates when document is
   * not visible or there still is a request in progress.
   *
   * @param {object} loader - Loader configuration.
   * @param {object} loader.collection - The collection of BehaviorSubjects.
   * @param {string | number} loader.key - The key for the specific BehaviorSubject.
   * @param {() => Observable<T>} loader.getData - The function to load data.
   * @param {object} pusher - Pusher configuration.
   * @param {string} pusher.channel - The pusher channel to listen to.
   * @param {string} pusher.event - The pusher event to listen to.
   * @param {number} [pusher.dueTime] - Optional due time for debouncing the updates.
   * @param {T} [initialValue=null] - The initial value for the BehaviorSubject.
   * @returns {BehaviorSubject<T>} - The managed BehaviorSubject.
   */
  public subjectManager<T>(
    loader: {
      collection: { [key: string]: BehaviorSubject<T> },
      key: string | number,
      getData: () => Observable<T>
    },
    pusher: {
      channel: string,
      event: string,
      dueTime?: number,
      condition?: (event: PusherMessage) => boolean
    },
    initialValue: T = null
  ): BehaviorSubject<T> {
    if (!loader.collection[loader.key]) {
      const index = this.pusherIndex + 0;
      const load = this.loader(loader.collection, loader.key, () => loader.getData(), index);

      loader.collection[loader.key] = new BehaviorSubject<T>(initialValue);

      load();

      this.pusherSubscriptions[index] = this.listen(pusher.channel, pusher.event, pusher.dueTime).subscribe((event: PusherMessage) => {
        if (!pusher.condition || pusher.condition(event)) {
          // Load only if the document is visible and previous request are completed.
          if (!this.documentHidden && !this.loaderInProgress[index]) load(true);
          else if (!this.pendingLoaders[loader.key]) {
            // Saves the loader, only once based on key, until it's visible again or the previous request is completed.
            this.pendingLoaders[loader.key] = () => {
              load(true);
            };
          }
        }
      });

      this.pusherIndex++;
    }

    return loader.collection[loader.key];
  }
}

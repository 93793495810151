import { Directive, HostListener, HostBinding, OnDestroy } from '@angular/core';
import { ScrollGroupDirective } from './scroll-group.directive';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[scrollItem]'
})
export class ScrollItemDirective implements OnDestroy {

  @HostBinding('scrollLeft')
  private left: number;

  private subscriptions: Subscription[] = [];

  constructor(
    private group: ScrollGroupDirective
  ) {
    this.subscriptions.push(this.group.left.subscribe((value) => {
      this.left = value;
    }));
  }

  @HostListener('scroll', ['$event'])
  doScroll(event) {
    this.group.setScrollLeft(event.target.scrollLeft);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

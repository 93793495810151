import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

export function FileTypeValidator(types: string): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {
    if (typeof control.value === 'string') return null;

    let extensions = (types || '').toLowerCase().split(',');

    let hasFile = control.value && control.value.length;
    let name = (control.value && control.value.length && control.value[0] && control.value[0].name) || '';
    let segments = name.split('.');
    let extension = (segments[segments.length - 1].toString()).toLowerCase();

    let isValid = !hasFile || !extensions.length || extensions.indexOf(extension) >= 0;

    return isValid ? null : {
      'fileType': {
        types: types
      }
    };
  };
}

@Directive({
  selector: '[fileType]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FileTypeDirective, multi: true }]
})
export class FileTypeDirective implements OnInit, Validator {

  @Input('fileType') private types: string;

  private validatorFn;

  constructor() { }

  ngOnInit(): void {
    this.validatorFn = FileTypeValidator(this.types);
  }

  validate(control: AbstractControl): { [key: string]: any } {
    this.validatorFn = FileTypeValidator(this.types);
    return this.validatorFn(control);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})
export class ColorSelectorComponent {

  @Input() public statusColor: string;
  @Input() public exampleText: string;

  @Output() readonly statusColorChange = new EventEmitter<string>();

  constructor() { }

  public palette: string[] = [
    '#90BE6D', // Pistachio
    '#F94144', // Red crayola
    '#F9C74F', // Maize crayola
    '#277DA1', // CG blue
    '#F3722C', // Orange red
    '#40A083', // Zomp
    '#F8961E', // Yellow orange
    '#4D908E', // Cadet blue
    '#F9844A', // Mango tango
    '#577590', // Queen blue
  ];

  public onSelect(colorSelected: string): void {
    this.statusColor = colorSelected
    this.statusColorChange.emit(colorSelected);
  }

  public isSelected(color: string): boolean {
    return this.statusColor === color;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { EnvelopeSigner } from '../models/envelope.model';
import { JSend } from '../models/jsend.model';
import { UploadS3Service } from '../ui/services/upload-s3.service';

@Injectable({
  providedIn: 'root'
})
export class SignersService {

  private baseUrl: string = '/:apiSignatures/companies/:companyId';
  private signersUrl: string = this.baseUrl + '/signers';
  private signerByIdUrl: string = this.signersUrl + '/:signerId';
  private fileByIdUrl: string = this.signerByIdUrl + '/files/:fileId';
  private uploadFilesSignersPath = '/:apiSignatures/companies/:companyId/signers/upload-url'

  constructor(
    private http: HttpClient,
    private uploadS3Service: UploadS3Service
  ) { }

  /**
   * Retrieves the list of signers for a given Company.
   * 
   * @param {number} companyId - The ID of the Company.
   * @returns {Observable<EnvelopeSigner[]>} - An observable containing the list of envelope signers.
   */
  public get(companyId: number): Observable<EnvelopeSigner[]> {
    const url = this.signersUrl.replace(":companyId", companyId.toString());

    return this.http.get<JSend<{
      signers: EnvelopeSigner[]
    }>>(url).pipe(map(response => {
      return plainToInstance(EnvelopeSigner, response.data.signers);
    }));
  }

  /**
   * Creates a new signer for a given Company.
   * 
   * @param {number} companyId - The ID of the Company.
   * @param {EnvelopeSigner} signer - The envelope signer details.
   * @param {any} [file] - An optional file to be uploaded.
   * @returns {Observable<EnvelopeSigner>} - An observable containing the created envelope signer.
   */
  public create(companyId: number, signer: EnvelopeSigner, file?: any): Observable<EnvelopeSigner> {
    const url = this.signersUrl
      .replace(":companyId", companyId.toString());

    const uploadFileUrl = this.uploadFilesSignersPath
      .replace(':companyId', companyId.toString());

    return this.uploadS3Service.upload(uploadFileUrl, [file])
      .pipe(
        switchMap((files) => {
          const signerData = { signer, file: files[0] };
          return this.http.post<JSend<{ signers: EnvelopeSigner }>>(url, signerData);
        }),
        map(({ data }) => plainToInstance(EnvelopeSigner, data.signers))
      );
  }

  public getSigner(companyId: number, signerId: number): Observable<EnvelopeSigner> {
    const url = this.signerByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":signerId", signerId.toString());

    return this.http.get<JSend<{
      signer: EnvelopeSigner
    }>>(url).pipe(map(response => {
      return plainToInstance(EnvelopeSigner, response.data.signer);
    }));
  }

  public delete(companyId: number, signerId: number): Observable<any> {
    const url = this.signerByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":signerId", signerId.toString());

    return this.http.delete<any>(url);
  }

  public getFile(companyId: number, signerId: number, fileId: number): Observable<any> {
    const url = this.fileByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":signerId", signerId.toString())
      .replace(":fileId", fileId.toString());

    return this.http.get<JSend<{
      file: any
    }>>(url).pipe(map(response => {
      return response.data.file;
    }));
  }
}

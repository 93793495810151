import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { Company } from '../../../../models/company.model';
import { PusherService } from '../../../../services/pusher.service';
import { constructFormData } from '../../../../utilities/construct-form-data';
import { buildFilters } from '../../../../utilities/filters';
import { simpleHash } from '../../../../utilities/json-tools';
import { Negotiation } from '../../commercial/models/negotiation.model';
import { ContractApplication } from '../../imported-data/models/contract-application.model';
import { Fixation } from '../../imported-data/models/fixation.model';
import { Contract, ContractStats } from '../models/contract.model';

/** [[Contract]] service. */
@Injectable()
export class ContractService {

  private baseUrl = '/:apiBase/companies/:companyId';
  private contractsUrl: string = this.baseUrl + '/contracts';
  // TODO: The path of this resource do not follow the conventions
  private contractsExportUrl: string = this.baseUrl + '/contract-export';
  // TODO: The path of this resource do not follow the conventions
  private contractStatsUrl: string = this.baseUrl + '/contract-stats';
  // Fixations Url
  private fixationsBaseUrl: string = this.baseUrl + '/fixations';
  private contractByIdPath: string = this.contractsUrl + '/:contractId';
  private fixationsPath: string = this.contractByIdPath + '/fixations';
  private fixationByIdPath: string = this.fixationsPath + '/:fixationId';
  // TODO: The path of this resource do not follow the conventions
  private contractNegotiationsPath: string = this.contractByIdPath + '/update-negotiation';
  // TODO: The path of this resource do not follow the conventions
  private contractRelatedNegotiationsPath: string = this.contractByIdPath + '/negotiations-to-link';
  private contractToTradeUrl: string = this.contractByIdPath + '/negotiations';
  private aplicacionesPath: string = this.baseUrl + '/aplicaciones';
  private contractMirrorBind: string = this.contractsUrl + '/bind';
  private contractMirrorUnbind: string = this.contractsUrl + '/unbind';

  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: Contract[]; headers: HttpHeaders }> } = {};
  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product_id]',
    'validity': 'filters[validity]',
    'label_id': 'filters[label_id]',
    'price': 'filters[price]',
    'past_range': 'filters[range]',
    'commercial_zone': 'filters[commercial_zone]',
    'crop': 'filters[crop]',
    'differences': 'filters[differences]',
    'warnings': 'filters[warnings]',
    'delivery_range': 'filters[delivery_range]',
    'fixation_period': 'filters[fixation_period]',
    'applied': 'filters[applied]',
    'settled': 'filters[settled]',
    'operation_type': 'filters[operation_type]',
    'destination_id': 'filters[destination_id]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private getContracts(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: Contract[]; headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.contractsUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Contract[]>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(Contract, response.body), headers: response.headers })));
  }

  public getMirrorContracts(company: Company, contract: Contract): Observable<{ body: Contract[]; headers: HttpHeaders }> {
    const filters = {};
    if (company.id === contract.buyer.id) {
      filters['filters[seller.name]'] = 'equal:' + company.name;
    } else {
      filters['filters[buyer.name]'] = 'equal:' + company.name;
    }

    filters['product_id'] = contract.product.id;
    filters['filters[quantity.value]'] = 'equal:' + contract.quantity.value;

    let url = this.contractsUrl.replace(':companyId', company.id.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Contract[]>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(Contract, response.body), headers: response.headers })));
  }

  public watch(companyId?: number, filters?: any, paginated: boolean = true): Observable<{ body: Contract[]; headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.getContracts(companyId, filters, paginated)
      },
      {
        channel: `company_${companyId}`,
        event: 'contracts'
      }
    );
  }

  // Get Fixations data by endpoint
  public getFixations(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Fixation[]; headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let fixationsUrl = this.fixationsBaseUrl.replace(':companyId', companyId.toString());
    fixationsUrl = buildFilters(fixationsUrl, filters, this.queryMap);

    const stream = this.http.get<Fixation[]>(fixationsUrl, { observe: 'response' });

    return stream.pipe(map((response) => ({ body: plainToInstance(Fixation, response.body), headers: response.headers })));
  }

  /** Creates a [[Contract]]. */
  public create(companyId: number, contract: Contract): Observable<Contract> {
    const url = this.contractsUrl.replace(':companyId', companyId.toString());
    const formData = constructFormData(contract);

    return this.http.post<Contract>(url, formData).pipe(map(c => plainToInstance(Contract, c)));
  }

  /** Edits a [[Contract]]. */
  public update(companyId: number, contract: Contract): Observable<Contract> {
    const url = this.contractByIdPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contract.id.toString());

    return this.http.put<Contract>(url, contract).pipe(map(response => plainToInstance(Contract, response)));
  }

  /** If negotiationId is not specified it will unlink the [[Contract]]. */
  public link(companyId: number, contract: Contract, negotiationId: number = null): Observable<Contract> {
    const url = this.contractNegotiationsPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contract.id.toString());

    const data = {
      negotiation: {
        id: negotiationId
      }
    };
    return this.http.put<Contract>(url, data).pipe(map(response => plainToInstance(Contract, response)));
  }

  /**
   * Get Negotiations that meet all these conditions:
   * - Same Product
   * - Matches one of these conditions:
   *  + Same buyer and seller Companies
   *  + Same buyer Company and seller Company is a broker
   *  + Same seller Company and buyer Company is a broker
   *
   * It could be currently linked to a Contract. Negotiations could have
   * multiple Contracts.
   */
  public related(companyId: number, contract: Contract): Observable<Negotiation[]> {
    const url = this.contractRelatedNegotiationsPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contract.id.toString());

    return this.http.get<Negotiation[]>(url).pipe(map(response => plainToInstance(Negotiation, response)));
  }

  public getById(companyId: number, contractId: number): Observable<Contract> {
    const url = this.contractByIdPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString());

    return this.http.get<Contract>(url).pipe(map(contract => plainToInstance(Contract, contract)));
  }

  /** Deletes a [[Contract]]. */
  public delete(companyId: number, contractId: number): Observable<any> {
    const url = this.contractByIdPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString());

    return this.http.delete(url);
  }

  /** Informs a [[Fixation|price fixing]] of a [[Contract]] to be fixed. */
  public addFixation(companyId: number, contractId: number, fixation: Fixation): Observable<Fixation> {
    const url = this.fixationsPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString());

    const formData = constructFormData(fixation);
    return this.http.post<Fixation>(url, formData).pipe(map(fix => plainToInstance(Fixation, fix)));
  }

  /** Deletes [[Fixation|price fixing]] information of a [[Contract]]. */
  public deleteFixation(companyId: number, contractId: number, fixationId: number): Observable<any> {
    const url = this.fixationByIdPath
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString())
      .replace(':fixationId', fixationId.toString());

    return this.http.delete<any>(url);
  }

  public exportToXLS(companyId: number, filters?: any): Observable<any> {
    let url = this.contractsExportUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    return this.http.get(url, { responseType: "blob" });
  }

  public getSats(companyId: number, filters?: any): Observable<{ body: ContractStats; headers: HttpHeaders }> {
    let url = this.contractStatsUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<ContractStats>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(ContractStats, response.body), headers: response.headers })));
  }

  public toTrade(companyId: number, contractId: number, participants: { [role: string]: Company }): Observable<any> {
    const url = this.contractToTradeUrl
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString());

    const formData = new FormData();

    for (const role in participants) {
      const company = participants[role];
      if (company) {
        formData.append(role + '_id', String(company.id));
      }
    }

    return this.http.post<any>(url, formData);
  }

  private getAplicaciones(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: ContractApplication[]; headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.aplicacionesPath
      .replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<ContractApplication[]>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(ContractApplication, response.body), headers: response.headers })));
  }

  public bind(companyId: number, contractId: number, contractIdToBind: number): Observable<any> {
    const url = this.contractMirrorBind
      .replace(':companyId', companyId.toString());

    const data = {
      id: contractId,
      bindings: [contractIdToBind]
    };
    return this.http.post<any>(url, data);
  }

  /** If negotiationId is not specified it will unlink the [[Contract]]. */
  public unbind(companyId: number, contractId: number, contractIdToUnbind: number): Observable<any> {
    const url = this.contractMirrorUnbind
      .replace(':companyId', companyId.toString());

    const data = {
      id: contractId,
      unbindings: [contractIdToUnbind]
    };
    return this.http.post<any>(url, data);
  }

  public watchAplicaciones(companyId?: number, filters?: any, paginated: boolean = true): Observable<{ body: ContractApplication[]; headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'contracts').pipe(
      startWith({}),
      mergeMap(event => this.getAplicaciones(companyId, filters, paginated))
    );
  }
}

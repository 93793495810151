import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Negotiation } from '../../../company/modules/commercial/models/negotiation.model';
import { NegotiationService } from '../../../company/modules/commercial/services/negotiation.service';
import { Contract } from '../../../company/modules/contracts/models/contract.model';
import { ContractService } from '../../../company/modules/contracts/services/contract.service';
import { Company, companyCan } from '../../../models/company.model';
import { CPEActors, ElectronicWaybill, SlotsBatch } from '../../../models/slots-batch.model';
import { CompanyService } from '../../../services/company.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { SlotService } from '../../../services/slot.service';

@Component({
  selector: 'batch-details',
  templateUrl: './batch-details.component.html',
  styleUrls: ['./batch-details.component.scss']
})
export class BatchDetailsComponent implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public set batch(b: SlotsBatch) {
    this._batch = b;
    this.checkActors();
    this.checkNegotiation();
  };
  public get batch(): SlotsBatch {
    return this._batch;
  }

  /** @deprecated */
  @Input() public actors: {
    [key: string]: Company
  };
  @Input() public edit: boolean;
  @Input() public contract: Contract;
  @Input('show-address') public showAddress: boolean = true;

  public editActors: boolean;
  public negotiation: Negotiation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public unsetteableKeys: string[];
  public customizableKeys: string[];

  /** @deprecated */
  public get bActors(): {
    [key: string]: Company
  } {
    return this.actors || this.batch.actors;
  }

  /** @deprecated */
  public get bWaybillActors(): {
    [key: string]: any
  } {
    return this.batch.waybillActors;
  }

  private _batch: SlotsBatch;
  private loadingNegotiation: boolean;
  private loadingContract: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    /** @ignore */
    public companyService: CompanyService,
    private contractService: ContractService,
    private negotiationService: NegotiationService,
    private slotService: SlotService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.unsetteableKeys = ['destinatario', 'destino'];
    this.customizableKeys = ['intermediario_de_flete', 'chofer', 'empresa_transportista'];
  }

  ngOnInit(): void {
    this.checkActors();
    this.checkNegotiation();
    this.checkContract();
  }

  private checkActors(): void {
    if (!this.bActors) {
      if (!this.batch.cpe || !this.batch.cpe.actors) {
        this.loadCPEActors();
      }
    }
  }

  private checkNegotiation(): void {
    if (this.batch.negotiation && !this.batch.negotiation.booking_date) {
      // Negotiation not loaded
      if (!this.loadingNegotiation) {
        this.loadingNegotiation = true;

        this.subscriptions.push(this.negotiationService.get(this.company.id, this.batch.negotiation.id).subscribe(negotiation => {
          this.batch.negotiation = negotiation;
          this.loadingNegotiation = false;
        }));
      }
    }
  }

  private checkContract(): void {
    if (this.batch.contract && !this.batch.contract.date) {
      // Negotiation not loaded
      if (!this.loadingContract) {
        this.loadingContract = true;

        this.subscriptions.push(this.contractService.getById(this.company.id, this.batch.contract.id).subscribe({
          next: contract => {
            this.batch.contract = contract;
            this.loadingContract = false;
          },
          error: error => {
            // The Contract does not exist or you do not have permission to view it
            this.loadingContract = false;
            this.dataDogLoggerService.warn(error.message, error.error);
          }
        }));
      }
    }
  }

  private _syngentaEntregador: Company;
  private syngentaEntregador(actors: CPEActors, entityObj: any): void {
    const normalize = (str: string): string => {
      return str.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    if ((!actors.representante_entregador || !actors.representante_recibidor) &&
      normalize(this.company.name).includes('SYNGENTA') &&
      entityObj.delivery?.locations?.length &&
      !normalize(entityObj.delivery.locations[0].location?.name).includes('NECOCHEA')) {
      const assign = () => {
        actors.representante_entregador = actors.representante_entregador || this._syngentaEntregador;
        actors.representante_recibidor = actors.representante_recibidor || this._syngentaEntregador;
      };

      if (this._syngentaEntregador) assign();
      else {
        this.subscriptions.push(this.companyService.getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, '30707386076').subscribe(companies => {
          const firstCompany = companies[0];

          if (firstCompany?.id) {
            // Only if a Company is found, cache is updated
            this._syngentaEntregador = firstCompany;
            assign();
          }
        }));
      }
    }
  }

  private loadCPEActors(): void {
    if (!this.processing && this.batch && this.batch.id && this.company) {
      this.processing = true;

      this.subscriptions.push(this.slotService.getActors(this.company.id, this.batch.id).subscribe(actors => {
        if (!this.batch.cpe) this.batch.cpe = new ElectronicWaybill();
        if (actors.destinatario) {
          this.batch.cpe.actors = new CPEActors(actors);
        } else {
          this.batch.cpe.actors = new CPEActors({
            destinatario: this.batch.recipient,
            destino: this.batch.destination.company
          });
        }

        // Assign actors from contract
        if (this.contract && this.edit) {
          const { actors } = this.batch.cpe;

          if (this.contract.brokers) {
            // Set corredor_venta_primaria if there is none
            if (!actors.corredor_venta_primaria) actors.corredor_venta_primaria = this.contract.brokers.pop();

            if (this.contract.brokers.length > 0 &&
              actors.corredor_venta_primaria.id != this.contract.brokers[0].id) {
              // Add the rest to observations
              actors.observaciones = [...(actors.observaciones || []), ...this.contract.brokers];
            }
            // actors.observaciones = [...(actors.observaciones || []), ...this.contract.brokers];
          }

          if (this.contract.seller) {
            if (companyCan(this.contract.seller).produce) {
              // Set remitente_comercial_productor if there is none
              actors.remitente_comercial_productor = actors.remitente_comercial_productor || this.contract.seller;
            } else {
              // Set remitente_comercial_venta_primaria if there is none
              actors.remitente_comercial_venta_primaria = actors.remitente_comercial_venta_primaria || this.contract.seller;
            }
          }

          if (this.contract.buyer && !actors.remitente_comercial_venta_secundaria) {
            // Set remitente_comercial_venta_secundaria if there is none
            actors.remitente_comercial_venta_secundaria = this.contract.buyer;
          }
          // Entregador para Syngenta
          this.syngentaEntregador(actors, this.contract);
        }

        this.processing = false;
      }));
    }
  }

  public removeActor(actorKey: string): void {
    if (this.isArray(this.batch.cpe.actors[actorKey])) {
      this.batch.cpe.actors[actorKey] = [];
    } else {
      this.batch.cpe.actors[actorKey] = null;
    }
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  public isArray(value: any): boolean {
    return Array.isArray(value);
  }

  /** @deprecated */
  public unsetActor(key: string): void {
    this.bActors[key] = undefined;
    this.batch.waybillActors[key] = undefined;
  }
  /** @deprecated */
  public setActor(key: string, value: any): void {
    this.batch.waybillActors[key] = value;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

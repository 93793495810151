import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { PusherMessage } from '../../models/pusher-message.model';
import { PusherService } from '../../services/pusher.service';
import { buildFilters } from '../../utilities/filters';
import { ApproverDecision, DoA } from '../models/doa.model';

@Injectable({
  providedIn: 'root'
})
export class DoAService {

  private baseUrl = '/:apiBase/delegation-of-authority';
  private delegationById = this.baseUrl + '/:id';
  private delegationVote = this.delegationById + '/vote';

  private readonly queryMap: Record<string, string> = {
    'status': 'filters[status]',
    // 'accounts': 'filters[accounts]',
    'includeExpired': 'filters[includeExpired]',
    'entity': 'filters[entityKey]',
    'entityId': 'filters[entityId]',
    // 'vote': 'filters[vote]'
    'companyId': 'filters[companyId]',
    'pendingAccountId': 'filters[pendingAccountId]',
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  /** Retrieves [[Comapny|Company's]] [[Envelope|Envelopes]]. */
  private get(filters: any): Observable<{ body: DoA[], headers: HttpHeaders }> {
    let url = this.baseUrl;

    url = buildFilters(url, filters, this.queryMap);

    return this.http.get<JSend<{
      delegations: DoA[]
    }>>(url, { observe: 'response' }).pipe(map(response => {
      return { body: plainToInstance(DoA, response.body.data.delegations), headers: response.headers };
    }));
  }

  public watch(userId: number, filters: any): Observable<{ body: DoA[], headers: HttpHeaders }> {
    return this.pusherService.listen(`private_${userId}`, 'doa').pipe(
      startWith({}),
      mergeMap(response => {
        return this.get(filters);
      })
    );
  }

  public watchByCompany(companyId: number, filters: any): Observable<{ body: DoA[], headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'doa').pipe(
      startWith({}),
      mergeMap(response => {
        return this.get(filters);
      })
    );
  }

  public create(doa: DoA): Observable<DoA> {
    let url = this.baseUrl;

    return this.http.post<JSend<{
      delegation: DoA
    }>>(url, doa).pipe(map(response => {
      return plainToInstance(DoA, response.data.delegation);
    }));
  }

  private getById(id: string): Observable<DoA> {
    let url = this.delegationById
      .replace(":id", id.toString());

    return this.http.get<JSend<{
      delegation: DoA
    }>>(url).pipe(map(response => {
      return plainToInstance(DoA, response.data.delegation);
    }));
  }

  public watchById(userId: number, id: string): Observable<DoA> {
    return this.pusherService.listen(`private_${userId}`, 'doa').pipe(
      startWith({}),
      mergeMap((event: PusherMessage) => {
        if (!event.data ||
          (event.data.id === id)) return this.getById(id);
      })
    );
  }

  public vote(doa: DoA, decision: ApproverDecision): Observable<DoA> {
    let url = this.delegationVote
      .replace(":id", doa.id.toString());

    return this.http.post<JSend<{
      delegation: DoA
    }>>(url, decision).pipe(map(response => {
      return plainToInstance(DoA, response.data.delegation);
    }));
  }
}

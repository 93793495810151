import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from "class-transformer";
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { Company } from '../../models/company.model';
import { Counterpart } from '../../models/counterpart.model';
import { PusherService } from '../../services/pusher.service';
import { buildFilters } from '../../utilities/filters';

@Injectable()
export class CounterpartsService {

  private baseUrl = '/:apiBase/companies/:companyId';
  private counterpartsUrl = this.baseUrl + '/counterparts';
  private blockCounterpartUrl = this.baseUrl + '/block-counterpart';
  private blockCounterpartByIdUrl = this.blockCounterpartUrl + '/:counterpartyId';
  private networkRelationUrl = this.baseUrl + '/network';
  private networkRelationByIdUrl = this.networkRelationUrl + '/:relationId';
  private networkRelationAcceptByIdUrl = this.networkRelationByIdUrl + '/accept';
  private inviteCompanyUrl = this.baseUrl + '/invite-company';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Counterpart[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.counterpartsUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    return this.http.get<Counterpart[]>(url, { observe: 'response' }).pipe(map(response => {
      return { body: plainToInstance(Counterpart, response.body), headers: response.headers };
    }));
  }

  public watch(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Counterpart[], headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'network').pipe(
      startWith({}),
      mergeMap(notification => {
        return this.get(companyId, filters, paginated);
      })
    )
  }

  public block(companyId: number, counterpartyId: number): Observable<any> {
    const url = this.blockCounterpartUrl.replace(':companyId', companyId.toString());

    return this.http.post<any>(url, { company_id: counterpartyId });
  }

  public unblock(companyId: number, counterpartyId: number): Observable<any> {
    const url = this.blockCounterpartByIdUrl
      .replace(':companyId', companyId.toString())
      .replace(':counterpartyId', counterpartyId.toString());

    return this.http.delete<any>(url);
  }

  public add(companyId: number, counterpartyId: number): Observable<any> {
    const url = this.networkRelationUrl.replace(':companyId', companyId.toString());

    return this.http.post<any>(url, { to_company_id: counterpartyId });
  }

  public accept(companyId: number, network_relation_id: number): Observable<any> {
    const url = this.networkRelationAcceptByIdUrl
      .replace(':companyId', companyId.toString())
      .replace(':relationId', network_relation_id.toString());

    return this.http.post<any>(url, {});
  }

  public remove(companyId: number, network_relation_id: number): Observable<any> {
    const url = this.networkRelationByIdUrl
      .replace(':companyId', companyId.toString())
      .replace(':relationId', network_relation_id.toString());

    return this.http.delete<any>(url);
  }

  public invite(companyId: number, companies: Company[]): Observable<any> {
    let url = this.inviteCompanyUrl.replace(":companyId", companyId.toString());

    return this.http.post<any[]>(url, companies);
  }
}

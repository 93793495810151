import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { instanceToInstance } from 'class-transformer';
import { Subject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CacheService } from '../../../services/cache.service';

// declare var jQuery: any;
declare var $: any;

const noop = () => { };

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectizeComponent),
  multi: true
};

@Component({
  selector: 'selectize',
  exportAs: 'selectize',
  templateUrl: './selectize.component.html',
  styleUrls: ['./selectize.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SelectizeComponent implements OnInit, OnDestroy {

  @Input() private reloadOnEvent: Subject<any>;
  @Input() public type: string;
  @Input() private maxItems: number = null;
  @Input() private minLength: number = 0;
  @Input() public name: string;
  /**
   * The name of the property to render as an option / item label (not needed
   * when custom rendering functions are defined).
   */
  @Input() public labelField: string;
  @Input() public searchField: string;
  @Input('cache') private cacheMinutes: number = 5;
  /**
   * An array of the initial options available to select; array of objects.
   */
  @Input() private options: Array<any> = [];
  /** An example value to display inside the field when empty. */
  @Input() public placeholder: string;
  @Input() public class: string = "";
  /**
   * If true, the load function will be called upon control initialization
   * (with an empty search). Alternatively it can be set to 'focus' to call the
   * load function when control receives focus.
   */
  @Input() private preload: boolean | string = true;
  @Input() private searchURL: Function;
  /** The name of the property to use as the unique value. */
  @Input() public valueField: string = "id";
  @Input() set disabled(v: boolean) {
    if (v !== this.isDisabled) {
      this.isDisabled = v;
      if (this.selectObject) {
        if (this.isDisabled) this.selectObject[0].selectize.disable();
        else this.selectObject[0].selectize.enable();
      }
    }
  }

  @Output() private readonly load = new EventEmitter();
  @Output() public readonly onItemAdd = new EventEmitter();
  @Output() public readonly onItemRemove = new EventEmitter();

  public isDisabled: boolean;
  get disabled(): boolean {
    return this.isDisabled;
  }

  public id: string;
  public initialized: boolean;
  public selection: any[] = [];

  private innerValue: any;
  private results: Array<any> = [];
  private selectObject: any;
  private subscriptions: Subscription[] = [];
  private loadSub: Subscription;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  /** @ignore */
  constructor(
    private http: HttpClient,
    private cacheService: CacheService
  ) { }

  get value(): any {
    return this.innerValue;
  };

  set value(v: any) {
    this.innerValue = v;
    this.onChangeCallback(this.cleanKey(v, '$order'));
  }

  /** @ignore */
  ngOnInit(): void {
    this.id = 'slctz_' + uuidv4();

    // el settimeout se utiliza como hack para que pueda instanciarse jquery en un elemento con nombre dinamico originado en angular
    setTimeout(() => {
      this.selectObject = $('#' + this.id).selectize({
        valueField: this.valueField,
        labelField: this.labelField || 'name',
        searchField: this.searchField || 'name',
        loadingClass: '',
        options: this.options,
        loadThrottle: 400, // The number of milliseconds to wait before requesting options from the server
        preload: this.preload,
        create: false,
        maxItems: this.maxItems,
        placeholder: this.placeholder,
        plugins: ['remove_button'],
        render: {
          item: (item, escape) => {
            if (typeof this.type === "undefined") {
              let s: string = '<div class="item"><span class="item-text">';
              s += escape(this.labelField ? item[this.labelField] : item.name);
              if (item.fiscal_id && item.fiscal_id.value) {
                s += ' <small class="text-muted">&mdash; ';
                if (item.fiscal_id.label) s += escape(item.fiscal_id.label) + ': ';
                s += '<samp>' + escape(item.fiscal_id.value) + '</samp>';
                s += '</small>';
              }
              s += '</span></div>';

              return s;
            } else if (this.type === "assignee") {
              let s: string = '<div class="item"><span class="item-text">';
              s += escape(item.name);
              if (item.last_name) s += ' ' + escape(item.last_name);
              if (item.email) s += ' <small class="text-muted">&mdash; ' + escape(item.email) + '</small>';
              s += '</span></div>';

              return s;
            } else if (this.type === "product" || this.type === "invoice") {
              let s = '<div class="item"><span class="item-text">' + escape(this.labelField ? item[this.labelField] : item.name) + '</span></div>';

              return s;
            }
          },
          option: (item, escape) => {
            if (typeof this.type === "undefined") {
              let s: string = '<div class="option">';
              s += '<b>';
              s += escape(this.labelField ? item[this.labelField] : item.name);
              s += '</b>';
              if (item.fiscal_id && item.fiscal_id.value) {
                s += ' <span class="micro">&mdash; ';
                if (item.fiscal_id.label) s += escape(item.fiscal_id.label) + ': ';
                s += '<samp>' + escape(item.fiscal_id.value) + '</samp>';
                s += '</span>';
              }
              if (item.activity && item.activity.name) s += '<div class="small text-muted">' + escape(item.activity.label || item.activity.name); + '</div>';
              s += '</div>';

              return s;
            } else if (this.type === "assignee") {
              let s: string = '<div class="option">';
              s += '<b>';
              s += escape(item.name);
              if (item.last_name) s += ' ' + escape(item.last_name);
              s += '</b>';
              if (item.fiscal_id && item.fiscal_id.value) {
                s += ' <span class="micro">&mdash; ';
                if (item.fiscal_id.label) s += escape(item.fiscal_id.label) + ': ';
                s += '<samp>' + escape(item.fiscal_id.value) + '</samp>';
                s += '</span>';
              }
              if (item.email) s += '<div class="small text-muted">' + escape(item.email); + '</div>';
              s += '</div>';

              return s;
            } else if (this.type === "product") {
              let s = '<div class="option">' + escape(item.name) + '</div>';

              return s;
            } else if (this.type === "invoice") {
              let s = '<div class="option">';
              s += '<div><b>' + escape(this.labelField ? item[this.labelField] : item.name) + '</b></div>';
              s += '<div class="text-muted small">';
              s += escape(item.company.name);
              s += ' &mdash; ';
              s += escape(item.recipient.name);
              s += '</div>';
              s += '</div>';

              return s;
            }
          }
        },
        load: (query, callback) => this.loadOptions(query, callback),
        onItemAdd: (itemId) => this.handleItemAdd(itemId),
        onItemRemove: (itemId) => this.handleItemRemove(itemId),
        // onChange: function (values) {          
        // },
        onDelete: (values) => this.handleItemDelete(values),
        onInitialize: () => this.handleInitialize()
      });

      if (this.reloadOnEvent) {
        this.subscriptions.push(this.reloadOnEvent.subscribe(event => {
          this.selectObject[0].selectize.onSearchChange('');
        }));
      }
    });
  }

  /**
   * Loads options based on the provided query and passes the results to a callback function.
   * If the results are cached, the cached results are used.
   * 
   * @param {string} query - The query string to search for options.
   * @param {Function} callback - The callback function to execute after options are loaded.
   */
  private loadOptions(query: string, callback: Function): void {
    if (this.options) this.results = this.options;
    if (this.searchURL === undefined) return;

    this.selectObject[0].selectize.clearOptions();
    this.selectObject[0].selectize.renderCache = {};

    this.loadSub?.unsubscribe();

    /**
     * Handles the finalization of the loading process by updating results and invoking the callback.
     * 
     * @param {any[]} response - The response data from the API or cache.
     */
    const done = (response: any[]) => {
      this.results = response;
      callback(response);
      this.load.emit({ query, results: response });
    };

    const url = this.searchURL(query);

    if (this.cacheMinutes && this.cacheService.get(url)) {
      done(this.cacheService.get(url));
    } else {
      this.loadSub = this.http.get<any[]>(url).subscribe({
        next: response => {
          this.cacheService.set(url, response, this.cacheMinutes);
          this.loadSub.unsubscribe();
          done(response);
        },
        error: error => callback()
      });
    }
  }

  private handleItemAdd(itemId: any): void {
    // Every time an item is added, we don't store the ID.
    // The object is searched in the results and is saved in the selection
    const item = this.results.find(item => String(item[this.valueField]) === String(itemId));
    if (item) this.addToSelection(item);
  }

  /** Removes an item from the selection by its ID. */
  private handleItemRemove(itemId: any): void {
    const itemIndex = this.selection.findIndex(item => String(item[this.valueField]) === String(itemId));

    if (itemIndex !== -1) {
      this.onItemRemove.emit(this.selection[itemIndex]);
      this.selection.splice(itemIndex, 1);
      this.value = this.selection;
    }
  }

  private handleItemDelete(values: any): boolean {
    return !(this.minLength > 0 && this.minLength === this.innerValue.length);
  }

  private handleInitialize(): void {
    if (this.isDisabled) this.selectObject[0].selectize.disable();
    this.initialized = true;
  }

  writeValue(value: any): void {
    if (value && value !== this.innerValue) {
      this.innerValue = value;
      this.updateSelection();

      if (this.innerValue && this.innerValue.forEach) {
        setTimeout(() => {
          this.innerValue.forEach(value => {
            this.selectObject[0].selectize.addOption(value);
            this.selectObject[0].selectize.addItem(value[this.valueField], true);
          });
        });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  updateSelection(): void {
    this.selection = this.innerValue || [];
  }

  private addToSelection(item: any): void {
    if (this.maxItems === 1) {
      this.selection = item;
    } else {
      // When maxItems is different than 1 the ngModel of the component is an array of items
      this.selection.push(item);
    }
    this.value = this.selection;
    this.onItemAdd.emit(item);
  }

  private cleanKey<T>(data: T[] | T, key: string): T[] | T {
    let tempData = instanceToInstance(data);
    if (Array.isArray(tempData)) {
      tempData.forEach(item => {
        delete item[key];
      });
    } else {
      delete tempData[key];
    }
    return tempData;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.loadSub?.unsubscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

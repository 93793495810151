import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityBinding } from '../../models/entity-binding.model';
import { PusherService } from '../../services/pusher.service';
import { CommercialZone } from './../models/commercial-zone.model';

@Injectable({
  providedIn: 'root'
})
export class CommercialZonesService {

  private baseUrl: string = '/:apiBase/companies/:companyId/commercial-zones';
  private zoneByIdUrl: string = this.baseUrl + '/:zoneId';
  private bindUrl: string = this.baseUrl + '/bind';

  private _collectionSubjects: { [companyId: number]: BehaviorSubject<CommercialZone[]> } = {};

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  /**
   * Watches for changes in commercial zones for a given company ID.
   *
   * @param {number} companyId - The ID of the company.
   * @returns {Observable<CommercialZone[]>} - An observable of commercial zones.
   */
  public watch(companyId: number): Observable<CommercialZone[]> {
    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: companyId,
        getData: () => this.get(companyId)
      },
      {
        channel: `company_${companyId}`,
        event: 'commercial_zones'
      },
      []);
  }

  /** Returns all [[Company|Comapny's]] [[CommercialZone|Commercial zones]]. */
  private get(companyId: number): Observable<CommercialZone[]> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString());

    return this.http.get<CommercialZone[]>(url).pipe(map(commercialZones => {
      return plainToInstance(CommercialZone, commercialZones);
    }));
  }

  /** Create a new [[CommercialZone|Commercial zone]]. */
  public create(companyId: number, commercialZone: CommercialZone): Observable<CommercialZone> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString());

    return this.http.post<CommercialZone>(url, commercialZone).pipe(map(commercialZone => {
      return plainToInstance(CommercialZone, commercialZone);
    }));
  }

  /** Updates a [[CommercialZone|Commercial zone]]. */
  public update(companyId: number, commercialZone: CommercialZone): Observable<CommercialZone> {
    const url = this.zoneByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":zoneId", commercialZone.id.toString());

    return this.http.put<CommercialZone>(url, commercialZone).pipe(map(commercialZone => {
      return plainToInstance(CommercialZone, commercialZone);
    }));
  }

  /** Deletes a [[CommercialZone|Commercial zone]]. */
  public delete(companyId: number, commercialZone: CommercialZone): Observable<CommercialZone> {
    const url = this.zoneByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":zoneId", commercialZone.id.toString());

    return this.http.delete<CommercialZone>(url);
  }

  /** Create a new [[CommercialZone|Commercial zone]]. */
  public bind(companyId: number, bindingData: EntityBinding[]): Observable<CommercialZone> {
    const url = this.bindUrl
      .replace(":companyId", companyId.toString());

    return this.http.post<CommercialZone>(url, bindingData);
  }
}

import { Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[scrollGroup]'
})
export class ScrollGroupDirective {

  public left = new Subject<number>();

  constructor() { }

  setScrollLeft(value:number) {
    this.left.next(value);
  }
}

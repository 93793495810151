import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityBinding } from '../models/entity-binding.model';
import { Label } from '../models/label.model';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  private labelUrl: string = '/:apiBase/companies/:companyId/labels';

  private _collectionSubjects: { [companyId: number]: BehaviorSubject<Label[]> } = {};

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private getURI(companyId: number): string {
    return this.labelUrl.replace(":companyId", companyId.toString());
  }

  /** Returns all [[Company|Comapny's]] Labels. */
  private get(companyId: number): Observable<Label[]> {
    const url = this.getURI(companyId);

    return this.http.get<Label[]>(url).pipe(map(labels => {
      return plainToInstance(Label, labels);
    }));
  }

  /**
   * Watches for changes in labels for a given company ID.
   *
   * @param {number} companyId - The ID of the company.
   * @returns {Observable<Label[]>} - An observable of labels.
   */
  public watch(companyId: number): Observable<Label[]> {
    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: companyId,
        getData: () => this.get(companyId)
      },
      {
        channel: `company_${companyId}`,
        event: 'labels',
        dueTime: 1000
      },
      []);
  }

  /** Create a new Label. */
  public create(companyId: number, label: Label): Observable<Label> {
    const url = this.getURI(companyId);

    return this.http.post<Label>(url, label).pipe(map(label => {
      return plainToInstance(Label, label);
    }));
  }

  /** Updates a Label. */
  public update(companyId: number, label: Label): Observable<Label> {
    const url = this.getURI(companyId) + `/${label.id}`;

    return this.http.put<Label>(url, label).pipe(map(label => {
      return plainToInstance(Label, label);
    }));
  }

  /** Deletes a Label. */
  public delete(companyId: number, label: Label): Observable<Label> {
    const url = this.getURI(companyId) + `/${label.id}`;

    return this.http.delete<Label>(url);
  }

  /** Create a new Label. */
  public bind(companyId: number, bindingData: EntityBinding[]): Observable<Label> {
    const url = this.getURI(companyId) + '/bind';

    return this.http.post<Label>(url, bindingData);
  }
}

import { Component, ElementRef, Input, OnDestroy, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchInputComponent),
  multi: true
};

export interface Searchable {
  id: number;
  name: string;
}

@Component({
  selector: 'search-input',
  exportAs: 'searchInput',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SearchInputComponent implements OnDestroy {

  @ViewChild(BsDropdownDirective, { static: true }) private readonly dropdown: BsDropdownDirective;
  @ViewChild('queryInput', { static: true }) private readonly queryInput: ElementRef;

  @Input() public name: string;
  @Input() public inputId: string;
  @Input() public classes: string;
  /** An example value to display inside the field when empty. */
  @Input() public placeholder: string;
  @Input() public disabled: boolean;
  @Input() private search: Function;
  @Input() private max: number;
  @Input() public groupBy: string;
  @Input() public itemTemplate: TemplateRef<any>;
  @Input() public type: string;
  @Input() public map: string;

  public query: string;
  public results: Searchable[];
  public selection: Searchable[] = [];
  public showSelector: boolean = true;
  public searching: boolean;

  private innerValue: any;
  private sTimeout: NodeJS.Timeout;
  private subscriptions: Subscription[] = [];

  constructor() { }

  get value(): any {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.updateSelection();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  doQuery(): void {
    this.searching = true;

    this.subscriptions.push(this.search(this.query).subscribe(results => {
      this.searching = false;
      this.results = results.slice(0, this.max || 10);
      if (this.dropdown) {
        if (this.results.length > 0) {
          this.dropdown.show();
        } else {
          this.dropdown.hide();
        }
      }
    }));
  }

  cancel(): void {
    this.dropdown.hide();
    this.query = '';
  }

  queryChange(event) {
    if (this.query.length > 2) {
      clearTimeout(this.sTimeout);

      this.sTimeout = setTimeout(() => {
        this.doQuery();
      }, 300);
    }
  }

  onBlurMethod(): void {
    this.updateValue();
  }

  updateValue(): void {
    if (this.type === 'object') {
      if (this.selection.length > 0) {
        this.value = this.selection[0];
        this.showSelector = false;
      } else {
        this.value = null;
        this.showSelector = true;
      }
    } else {
      this.value = this.selection.map(item => {
        return this.map ? item[this.map] : item;
      });

      if (this.selection.length > 0 && this.type === 'single') {
        this.showSelector = false;
      } else {
        this.showSelector = true;
      }
    }
  }

  updateSelection(): void {
    if (this.type === 'object' || this.type === 'single') {
      if (this.innerValue) {
        this.selection = [this.innerValue];
        this.showSelector = false;
      } else {
        this.selection = [];
        this.showSelector = true;
      }
    } else {
      // TODO:
    }
  }

  select(item: Searchable) {
    this.query = '';
    this.selection.push(item);
    this.updateValue();
    this.dropdown.hide();
  }

  remove(item: Searchable) {
    this.selection = this.selection.filter(function (i) {
      return i !== item;
    });
    this.updateValue();
  }

  focus(): void {
    this.queryInput.nativeElement.focus();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { Injectable } from '@angular/core';

type CacheItem = {
  due_date: Date;
  value: any;
};

/** In-memory cache. */
@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private readonly DEFAULT_KEY = 'DEFAULT';

  /**
   * Map used for caching. `Map` is preferred over a JSON object for performance reasons:
   * - `map.has(key)` is faster than `obj.hasOwnProperty(key)` or `obj[key] !== undefined`.
   * - `Map` allows keys of any type.
   */
  private cache: Map<string | object, CacheItem> = new Map();

  constructor() { }

  /**
   * Retrieves a cached item based on the provided object or the default key.
   * 
   * @param {any} [object] - The object used as a key to retrieve the cached value.
   * @returns {any | null} - The cached value, or `null` if the item is expired or not found.
   */
  public get(object?: any): any | null {
    const key: string | object = object ? object : this.DEFAULT_KEY;
    const item: CacheItem | undefined = this.cache.has(key) ? this.cache.get(key) : undefined;

    if (!item) return null;

    const now = new Date();

    if (now.getTime() > item.due_date.getTime()) {
      return null;
    }

    return item.value;
  }

  /**
   * Caches a value with an optional expiration time and key.
   * 
   * @param {any} [object] - The object used as a key for caching.
   * @param {any} value - The value to cache.
   * @param {number} [cache_in_minutes=5] - The time in minutes before the cache expires.
   */
  public set(object: any, value: any, cache_in_minutes: number = 5): void {
    const key = object ? object : this.DEFAULT_KEY;
    const due_date = new Date();

    due_date.setSeconds(due_date.getSeconds() + (cache_in_minutes * 60));

    this.cache.set(key, { due_date, value });
  }

  /**
   * Clears all cached items.
   */
  public clear(): void {
    this.cache.clear();
  }
}

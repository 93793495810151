import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CompanyCertificate } from '../../models/company-certificate.model';
import { CacheService } from '../../services/cache.service';
import { constructFormData } from '../../utilities/construct-form-data';

@Injectable()
export class CertificatesService {

  private certificatesUrl = '/:apiBase/companies/:companyId/certificates';
  private certificateUrl = this.certificatesUrl + '/:certificateId';

  private readonly CACHE_MINUTES = 60 * 24;

  constructor(
    private cacheService: CacheService,
    private http: HttpClient
  ) { }

  public get(companyId: number): Observable<CompanyCertificate[]> {
    const url = this.certificatesUrl.replace(':companyId', companyId.toString());
    const stream = this.http.get<CompanyCertificate[]>(url);

    return stream.pipe(map(company_certificates => {
      return plainToInstance(CompanyCertificate, company_certificates);
    }));
  }

  public create(companyId: number, company_certificate: CompanyCertificate): Observable<CompanyCertificate> {
    const url = this.certificatesUrl.replace(':companyId', companyId.toString());
    const form_data = constructFormData(company_certificate);

    return this.http.post<CompanyCertificate>(url, form_data);
  }

  public delete(companyId: number, companyCertificateId: number): Observable<any> {
    const url = this.certificateUrl
      .replace(':companyId', companyId.toString())
      .replace(':certificateId', companyCertificateId.toString());

    return this.http.delete(url);
  }

  /**
   * Retrieves certificate types.
   * @returns {Observable<any>} - The list of certificate types.
   */
  public getTypes(): Observable<any> {
    const url = '/:apiBase/certificate-types';

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get(url).pipe(
      tap(data => this.cacheService.set(url, data, this.CACHE_MINUTES))
    );
  }

  /**
   * Retrieves certificate entities.
   * @returns {Observable<any>} - The list of certificate entities.
   */
  public getEntities(): Observable<any> {
    const url = '/:apiBase/certificate-entities';

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get(url).pipe(
      tap(data => this.cacheService.set(url, data, this.CACHE_MINUTES))
    );
  }

  /**
   * Retrieves certificates by type.
   * @param {number} typeId - The ID of the certificate type.
   * @returns {Observable<any>} - The certificates for the specified type.
   */
  public getByType(typeId: number): Observable<any> {
    const url = '/:apiBase/certificate-types/:typeId/certificates'.replace(':typeId', typeId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get(url).pipe(
      tap(data => this.cacheService.set(url, data, this.CACHE_MINUTES))
    );
  }
}

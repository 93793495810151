import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FileManagerFile } from '../../models/file-manager-file.model';
import { JSend } from '../../models/jsend.model';
import { UploadS3Service } from '../../ui/services/upload-s3.service';
import { buildFilters } from '../../utilities/filters';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  private baseUrl: string = '/:apiDrive/companies/:companyId';
  private filesUrl: string = this.baseUrl + '/files';
  private remotefilesUrl: string = this.baseUrl + '/remote-files';
  private fileById: string = this.filesUrl + '/:fileId';
  private fileShare: string = this.fileById + '/share';
  private generateUrlsToUploadUrl = this.baseUrl + '/url';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient,
    private uploadS3Service: UploadS3Service
  ) { }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: FileManagerFile[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.filesUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{
      files: FileManagerFile[]
    }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(FileManagerFile, response.body.data.files), headers: response.headers };
    }));
  }

  public getFile(companyId: number, fileId: string): Observable<FileManagerFile> {
    const url = this.fileById
      .replace(":companyId", companyId.toString())
      .replace(":fileId", fileId);

    return this.http.get<JSend<{
      file: FileManagerFile
    }>>(url).pipe(map(response => {
      return plainToInstance(FileManagerFile, response.data.file);
    }));
  }

  public delete(companyId: number, file: FileManagerFile): Observable<JSend> {
    const url = this.fileById
      .replace(":companyId", companyId.toString())
      .replace(":fileId", file.id);

    return this.http.delete<JSend>(url);
  }

  public edit(companyId: number, file: FileManagerFile): Observable<FileManagerFile> {
    const url = this.fileById
      .replace(":companyId", companyId.toString())
      .replace(":fileId", file.id);

    return this.http.put<JSend<{
      file: FileManagerFile
    }>>(url, file).pipe(map(response => {
      return plainToInstance(FileManagerFile, response.data.file);
    }));
  }

  public upload(companyId: number, type: string, files: File[], label?: string, dueDate?: Date): Observable<FileManagerFile[]> {
    const getPresignedUrls = this.generateUrlsToUploadUrl
      .replace(":companyId", String(companyId));
    const uploadFilesUrl = this.filesUrl
      .replace(":companyId", String(companyId));

    return this.uploadS3Service.upload(getPresignedUrls, files).pipe(
      switchMap(uploadedFiles =>
        this.http.post<JSend<{ files: FileManagerFile[] }>>(uploadFilesUrl, {
          company_id: companyId, // Owner
          file_keys: uploadedFiles, // S3 Files
          type, // Type key for mapping
          label, // Human-friendly type label
          dueDate
        }).pipe(
          map(response => plainToInstance(FileManagerFile, response.data.files))
        ))
    );
  }

  public uploadFrom(companyId: number, type: string, files: FileManagerFile[]): Observable<FileManagerFile[]> {
    const url = this.remotefilesUrl
      .replace(":companyId", String(companyId));

    return this.http.post<JSend<{ files: FileManagerFile[] }>>(url, {
      type: type,
      files: files
    }).pipe(map(response => {
      return plainToInstance(FileManagerFile, response.data.files);
    }));
  }

  public share(companyId: number, file: FileManagerFile, shareWith: number[]): Observable<JSend> {
    const url = this.fileShare
      .replace(":companyId", companyId.toString())
      .replace(":fileId", file.id);

    return this.http.post<JSend>(url, {
      share: shareWith
    });
  }
}
